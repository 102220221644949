// var onScroll = function (event){
// 		var scrollPos = $(document).scrollTop();
// 		$('#page-content-menu ul li a').each(function () {
// 				var currLink = $(this);
// 				var refElement = $(currLink.attr("href"));
// 				if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
// 						$('#page-content-menu ul li a').parent('li').removeClass("active");
// 						$('#page-content-menu ul li a').removeClass("clr-active");
// 						currLink.parent('li').addClass("active");
// 						currLink.addClass("clr-active");
// 				}
// 		});
// }

var elementPosition = $('#page-content').offset();

$(function() {

	// $(document).on("scroll", onScroll);

	$(window).scroll(function(){
		if($('#page-content').length)
			if($(window).scrollTop() > elementPosition.top){
				$('#toc__page-wrapper').css('position','fixed').css('top','3%');		
				$('#page-content').css('position','relative').css('top','auto');
			} else {
				$('#toc__page-wrapper').css('position','relative');
			}
	});


	var pl = 260;
	$(window).scroll(function() {
			if ($('#content-bar').height() <= ($(window).height() + $(window).scrollTop()) - pl) {
					$('#page-content').hide();
					$('#toc__page-wrapper').hide();
			}else{
					$("#page-content").show();
					$("#toc__page-wrapper").show();
			}
	});

	var $document   = $(document),
		$inputRange = $('input[type="range"]');

	// Example functionality to demonstrate a value feedback
	function valueOutput(element) {
			var value = element.value,
					output = element.parentNode.getElementsByTagName('output')[0];

			// format money
			if($(element).attr('max') > 999) {
				value = Number(value).toLocaleString("en-US").split('.')[0];

				if(parseInt(element.value) === parseInt($(element).attr('max'))) {
					value += '+';
				}
			}

			output.innerHTML = value;
	}
	for (var i = $inputRange.length - 1; i >= 0; i--) {
			valueOutput($inputRange[i]);
	};
	$document.on('input', 'input[type="range"]', function(e) {
			valueOutput(e.target);
	});
	// end

	$inputRange.rangeslider({
		polyfill: false
	});

	// animate on click
	$("a.animate").on('click', function(event) {
		var current_link = $(this);
		if (this.hash !== "") {
			event.preventDefault();

			var hash = this.hash;

			$('html, body').animate({
				scrollTop: $(hash).offset().top
			}, 800, function(){

				window.location.hash = hash;
			});
		}
	});
	//
	// $.scrollUp({
	// 	scrollName: 'go-top-btn',
	// 	scrollText: '[back to top &uarr;]'
	// });


	$('#faq-wrapper a').click(function(e) {
		e.preventDefault();
	});

	$('#comment-login').popover();

	// remove hash modifier
	const $hash = window.location.hash;
	window.location.hash = $hash.replace('-hashmod', '');

	// sidebar scroll animate
	$('body').on('click', '#page-content a[href^="#"]', function (e) {
			e.preventDefault();
			$(document).off("scroll");
			const navbar_height = $('.guide-3 .navbar-light').outerHeight();
			const target = this.hash;
			const $target = $(target);
			const $target_top = $target.offset().top - ( $('.guide-3').length > 0 ? navbar_height : 0 );
			$('html, body').stop().animate({
					'scrollTop': $target_top
			}, 500, 'swing', function () {
					window.location.hash = target + '-hashmod';
			});
	});

	

	// clone toc
	$('#toc_container .toc_title, #toc_container .toc_number').remove();
	$('#toc_container > ul').addClass('nav');
	var tocContent = $('#toc_container').html();
	$(".toc-clone").append(tocContent);
	var post_title = $('.the_guide_title').text();
	$('.toc_list').prepend('<li class="go-top"><a href="#page-top">'+post_title+'</a></li>');
	$('.toc_list').append('<ul class="top"><li class="go-top"><a href="#page-top">Back to top &uarr;</a></li></ul>');

	// move wrap
	$('h2 span, h3 span, h4 span, h5 span, h6 span').each(function(){
		var span_id = $(this).attr('id') !== undefined ? $(this).attr('id') : '',
			span_text = $(this).text();

		if(span_id !== '') {
			$(this).parent('h1, h2, h3, h4, h5, h6').addClass('add_offset_padding').attr('id', span_id).text(span_text);
		}
	});

	$('.rebate-wrapper > h4').removeAttr('id');

	$('[data-spy="scroll"]').each(function () {
		  var $spy = $(this).scrollspy('refresh')
	 });

	 var sidebar_width = $('.page-content-wrapper').outerWidth(true) - 30;

	 if($('#toc_container').length < 1) {
		 $('#page-content').hide().remove();
		 $('.page-content-wrapper #sidebar-form').fadeIn();

		 var sidebar_top = $('.page-content-wrapper #sidebar-form').offset();

		 $('.page-content-wrapper #sidebar-form').affix({
		  offset: {
		    top: function(){
				return (this.top = sidebar_top.top)
			},
		    bottom: function () {
		      return (this.bottom = $('#more-articles').outerHeight(true) + $('footer').outerHeight(true) + 100)
		    }
		  }
	  	});

		sidebar_form_width()
	 }

	 $(window).resize(function(){
		 sidebar_form_width()
	 });

	 function sidebar_form_width() {
		 sidebar_width = $('.page-content-wrapper').outerWidth(true) - 30;
		 $('.page-content-wrapper #sidebar-form').css({width: sidebar_width})
	 }

	// ****************** calculator specific ************************** //

	// validation
	$('.calculator-form').each(function(){
		$(this).validate();
	});

	// waiting list
	var waiting_list_data;

	$.getJSON(templurl + "/common/js/waiting-list-data.json", function(data, x, y) {
		waiting_list_data = data;
	});

	$('#form_gp_hwl').submit(function(e){
		e.preventDefault();
		get_waiting_list();
	});

	$('#form_gp_hwl select').on('change', function(){
		$('#result-table-waiting').fadeOut();
	});

	function get_waiting_list() {

		var procedure = $('#procedure_gp_hwl').val(),
			state = $('#state_gp_hwl').val();

		$('#result-table-waiting').fadeOut();

		if(procedure == undefined || procedure == '' || state == undefined || state == '')
			return false


		var procedure = 'procedure' + procedure,
			state = parseInt(state);

		$('.admission-result span').text(waiting_list_data[procedure][state].admission);
        $('.percentile50-result span').text(waiting_list_data[procedure][state].percentile50);
        $('.percentile90-result span').text(waiting_list_data[procedure][state].percentile90);
        $('.days365-result span').text(waiting_list_data[procedure][state].days365);

		$('.selected_procedure').text($('#procedure_gp_hwl option:selected').text());

		$('.selected_state').text($('#state_gp_hwl option:selected').text());

        setTimeout(function(){
        	$('#result-table-waiting').slideDown();
        }, 300);
	}

	//  rebate calculator
	$('#form_gp_rebate button').on('click', function(e) {
		e.preventDefault();
		calulate_rebate();
	});

	function calulate_rebate() {
		var income = $("#annual_income_gp_rebate").val();
		var age = $("#age_gp_rebate").val();
		var status = $("#status_gp_rebate").val();
		var percentage = 0;
		var result = 0;

		console.log(status, income, age)

		if(status == "single") {

			if(income < 90001) {

				if (age < 65) {

					percentage = 25.059;

				} else if (age > 64 && age < 70) {

					percentage = 29.236;

				} else {

					percentage = 33.413;

				}

			} else if(income > 90000 && income < 105001) {

				if (age < 65) {

					percentage = 16.706;

				} else if (age > 64 && age < 70) {

					percentage = 20.883;

				} else {

					percentage = 25.059;

				}

			} else if(income > 105000 && income < 140001) {


				if (age < 65) {

					percentage = 8.352;

				} else if (age > 64 && age < 70) {

					percentage = 12.529;

				} else {

					percentage = 16.706;

				}


			} else if(income > 140000) {

				percentage = 0.0;
				result = 0;
			}

		} else if(status == "families") {


			if(income < 180001) {

				if (age < 65) {

					percentage = 25.059;

				} else if (age > 64 && age < 70) {

					percentage = 29.236;

				} else {

					percentage = 33.413;

				}

			} else if(income > 180000 && income < 210001) {

				if (age < 65) {

					percentage = 16.706;

				} else if (age > 64 && age < 70) {

					percentage = 20.883;

				} else {

					percentage = 25.059;

				}

			} else if(income > 210000 && income < 280001) {

				if (age < 65) {

					percentage = 8.352;

				} else if (age > 64 && age < 70) {

					percentage = 12.529;

				} else {

					percentage = 16.706;

				}

			} else if(income > 280000) {

				percentage = 0.0;
			}
		}

		result = parseFloat(income * (percentage / 100) );
		$('#rebate-value').text(percentage + "%");
	}


    // levy surcharge
	$('#form_gp_levy').submit(function(e){
		e.preventDefault();
		get_levy_surcharge();
	});

	function get_levy_surcharge() {
		var income = $("#form_gp_levy_income").val();
		var status = $("#form_gp_levy_status").val();
		var percentage = 0;
		var result = 0;
		$('#form_gp_levy_result').hide();

		if(income < 90001 && status == 'single' || income < 180001 && status == 'family') {

			percentage = 0.0;
			result = 0;

		} else if((income > 90000 && income < 105001 && status == 'single') || (income > 180000 && income < 210001 && status == 'family') ) {

			percentage = 1.0;
			result = parseFloat(income * 0.01);

		} else if((income > 105000 && income < 140001 && status == 'single') || (income > 210000 && income < 280001 && status == 'family')) {

			percentage = 1.25;
			result = parseFloat(income * 0.0125);


		} else if(income > 140000 && status == 'single' || income > 280000 && status == 'family') {

			percentage = 1.5;
			result = parseFloat(income * 0.0150);
		}

		result = parseFloat(result.toFixed(2));

		$('#form_gp_levy_result strong').text(percentage.toFixed(2) + "%");
		$('#rebate-value-levy').text("$" + result.toLocaleString("en-US"));

		$('#form_gp_levy_result').fadeIn();
	}

    // policy costs
	$('#form_gp_postcalc').submit(function(e){
		e.preventDefault();
		get_policy_cost();
	});

	$('#form_gp_postcalc input[type="radio"]').on('change', function(){
		get_policy_cost();
	});

	$('#form_gp_postcalc input[type="tel"]').on('keyup',function(){
		var current_cost = $(this).val();
		if(current_cost === '$') {
			current_cost = 0;
			$(this).val('');
		}
		if(current_cost !== '' && current_cost !== '0') {
			current_cost = '$' + Number(parseFloat(current_cost.replace(/[\,\$]+/g, "",''))).toLocaleString("en-US");
			$(this).val(current_cost);
			get_policy_cost();
		}

	});

	function get_policy_cost() {
		$('#form_gp_postcalc span').text('$0.00');

		if($('#cost-per-month').val() !== '' && $('#cost-per-month').val() !== '0' ) {
			var cost_per_month = parseFloat($('#cost-per-month').val().replace(/[\,\$]+/g, "",''));
			var radio_checked = $('#form_gp_postcalc input:checked').val();
			$('#form_gp_postcalc span').text('');

			switch (radio_checked) {
				case "daily":
					result = parseFloat(cost_per_month / 30).toFixed(2);
					break;
				case "fortnightly":
					result = parseFloat((cost_per_month / 30) * 14).toFixed(2);
					break;
				case "quarterly":
					result = parseFloat(cost_per_month * 4).toFixed(2);
					break;
				case "annually":
					result = parseFloat(cost_per_month * 12).toFixed(2);
					break;
			}

			$('#form_gp_postcalc input:checked').next('span').text('$' + Number(result).toLocaleString("en-US"))

		} else {
			$('#form_gp_postcalc input[type=submit]').click();
		}
	}

    // waiting period
	var current_date = new Date();

	$('#form_gp_waitingperiod').submit(function(e){
		e.preventDefault();
		get_waiting_period();
	});

	$('#calendar-pick').datepicker({
		startDate: '0d',
		format: 'dd/mm/yyyy'
	}).on('changeDate', function(e) {
		$(this).datepicker('hide');
    });

	$('#calendar-pick').focus(function(){
		$('#form_gp_waitingperiod_result').slideUp();
	});

	$('#calendar-pick').val(current_date.toString('dd/MM/yyyy'))

	function get_waiting_period() {

		var calc_waiting_val_arr = $('#calendar-pick').val().split('/');
		var calc_waiting_val = calc_waiting_val_arr[1] + "/" + calc_waiting_val_arr[0] +  "/" + calc_waiting_val_arr[2] + " 12:00 AM";

		var waiting_result_1 = new Date(calc_waiting_val).add({months: 12});
		var waiting_result_2 = new Date(calc_waiting_val).add({months: 2});

		$('#calc-waiting-result-1 span').text(waiting_result_1.toString('MMMM dd, yyyy'));
		$('#calc-waiting-result-2 span').text(waiting_result_1.toString('MMMM dd, yyyy'));
		$('#calc-waiting-result-3 span').text(waiting_result_2.toString('MMMM dd, yyyy'));
		$('#calc-waiting-result-4 span').text(waiting_result_2.toString('MMMM dd, yyyy'));

		$('#form_gp_waitingperiod_result').fadeIn();
	}




	// ****************** /calculator specific ************************** //


	// in post form
	$('.formstep select, .formstep input').each(function(){
		$(this).addClass('form-control')
	});

	window.prettyPrint && prettyPrint()
	$(document).on('click', '.yamm .dropdown-menu', function(e) {
		e.stopPropagation()
	});


	// reviews page scripts
	if($('.review_score').length >= 0) {
		$.ajax({
	 		type: "GET",
			url: templurl + '/inc/_feefo-request.php',
	 		success: function(data) {

				var data = JSON.parse(data);

			   // summary
	 		   var feedbackData = data.FEEDBACKLIST.FEEDBACK;
	 		   var summaryData = data.FEEDBACKLIST.SUMMARY;
	 		   var summaryAverage = parseFloat("0." + summaryData.AVERAGE);
	 		   var fiveStarAverage = summaryData.FIVESTARAVERAGE.toString().indexOf('.') != -1 ? summaryData.FIVESTARAVERAGE : summaryData.FIVESTARAVERAGE.toString() + '.0';
	 		   var summary_score = 5 * summaryAverage;


	 		   // $('.star-after').css('width', summaryData.AVERAGE + "%");
	 		   $('.review_score').text(fiveStarAverage);

	 		}
	 	}); //close $.ajax
	}


	// menu search form
	$('#menu_searchform .search-btn').click(function(e){
		e.preventDefault();
		if($('#menu_searchform').hasClass('active')) {
			if($('#menu_searchform input').val() !== '')
				$('#menu_searchform').submit();
			else
				$('#menu_searchform').removeClass('active');
		} else {
			$('#menu_searchform').addClass('active');
			$('#menu_searchform input[type=text]').focus();
		}
	});

	$(document).mouseup(function(e){
		var container = $("#menu_searchform");
		if (!container.is(e.target) && container.has(e.target).length === 0)
		{
			$('#menu_searchform').removeClass('active');
		}
	});

	  $('#navbar').find('a').each( function(){
		if ($(this).prop("id").length === 0){
		  var id_menu = $(this).clone();
		  id_menu.find('small').remove().end();
		  id_menu.find('img').remove().end();
  
		  id_menu = id_menu.text().trim().toLowerCase().replace(/\s/g, '_');
  
		  if ($(this).text() !== ""){
			$(this).attr('id', 'hdr_link_' + id_menu);
		  }
		}
	  });
  
	  $('.footer__list').find('a').each( function() {
		var idFooterMenu = $(this);
		if (idFooterMenu.prop("id").length === 0){
		  idFooterMenu = idFooterMenu.text().trim().toLowerCase().replace(/\s/g, '_');
  
		  if ($(this).text() !== ""){
			$(this).attr('id', 'ftr_link_' + idFooterMenu);
		  }
		}
	  });


});


$(document).ready(function() {
	var offsetHeight = $('#page-content').length > 0 ? $('#page-content').outerHeight() : 0;
	if (offsetHeight >= 400) {
		$("#toc__widget-sidebar #toc__page-wrapper").addClass('static__page-content');
	}

	$('#single-profile-testimonial').slick({
		infinite: true,
		autoplay: true,
		fade: true,
		nextArrow: '<div class="arrow-left arrow"><img src="https://asset.healthinsurancecomparison.com.au/wp-content/uploads/2019/09/08140912/icons8-chevron-left-26-2.png"></div>',
		prevArrow: '<div class="arrow-right arrow"><img src="https://asset.healthinsurancecomparison.com.au/wp-content/uploads/2019/09/08140912/icons8-chevron-left-26-2.png"></div>',
		adaptiveHeight: true
	});

});
 